//Define type styles that can be used as utility classes or mixins elsewhere.

@mixin heading-1 {
    font-family: $font-stack-headings;
    font-weight: 400;
    @include size-3XL;
    line-height: 1.1;
}

@mixin heading-2 {
    font-family: $font-stack-headings;
    font-weight: 400;
    @include size-2XL;
    line-height: 1.2;
}

@mixin heading-3 {
    font-family: $font-stack-headings;
    font-weight: 400;
    @include size-XL;
    line-height: 1.33;
}

@mixin heading-4 {
    font-family: $font-stack-headings;
    font-weight: 400;
    @include size-L;
    line-height: 1.33;
}

@mixin heading-5 {
    font-family: $font-stack-headings;
    font-weight: 400;
    @include size-M;
    line-height: 1.33;
}
@mixin display-Text {
    font-family: $font-stack-primary;
    font-weight: 300;
    @include size-2XL;
    line-height: 1.33;
}

@mixin subtitle-1 {
    font-family: $font-stack-primary;
    font-weight: 300;
    @include size-L;
    line-height: 1.5;
}

@mixin subtitle-2 {
    font-family: $font-stack-primary;
    font-weight: 300;
    @include size-M;
    line-height: 1.5;
}

@mixin body-L {
    font-family: $font-stack-primary;
    font-weight: 400;
    @include size-L;
    line-height: 1.5;
}

@mixin body-M {
    font-family: $font-stack-primary;
    font-weight: 400;
    @include size-M;
    line-height: 1.5;
}

@mixin body-S {
    font-family: $font-stack-primary;
    font-weight: 400;
    @include size-S;
    line-height: 1.5;
}

@mixin body-XS {
    font-family: $font-stack-primary;
    font-weight: 400;
    @include size-XS;
    line-height: 1.5;
}

@mixin overline {
    font-family: $font-stack-primary;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.125em;
    @include size-XS;
    line-height: 1.5;
}

@mixin button-text {
    font-family: $font-stack-headings;
    font-weight: 400;
    @include size-M;
    line-height: 1.5;
}

@mixin label {
    font-family: $font-stack-headings;
    font-weight: 400;
    @include size-XS;
    line-height: 1.5;
}