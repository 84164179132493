//Form Styling

form {
    //Set vertical rhythm for all child elements of a form
    > * {
        margin-bottom: 1.5rem;

        &.no-margin {
            margin-bottom: 0;
        }
        //Remove margin-bottom for last element
        &:last-child {
            margin-bottom: 0;
        }
    }
    .row {
        display: flex;
        margin-left: -1rem;
        margin-right: -1rem;

        @include breakpoint(XS) {
            flex-direction: column;
            margin: 0;
        }
    }
    .full-width {
        padding: 0 1rem;
        width: 100%;

        @include breakpoint(XS) {
            padding: 0;
            margin-bottom: 1rem;
        }
    }
    .half {
        width: 50%;
        margin: 0;
        padding: 0 1rem;

        @include breakpoint(XS) {
            width: 100%;
            padding: 0;
            margin-bottom: 1rem;
        }
    }
}

//Label Styling
label {
    font-weight: 700;
    @include size-XS;
    font-family: $font-stack-headings;
    @extend %transition;
    @include color(color, 'body');

    //Apply margin between labels and inputs
    input,
    textarea,
    .dropdown {
        margin-top: $unit;
    } 

    //Active styling
    &.active {
        @include color(color, 'accent-primary');
    }
}

//Standard Input Styling

input,
textarea,
.dropdown {
    //Resets
    display: block;
    box-shadow: none;
    -webkit-appearance: none;
    position: relative;
    z-index: 2;
    background: transparent;
    color: $color-body;
    border:none;
    border-bottom: solid 1px;
    @include color(border-color, 'body');

    font-family: $font-stack-primary;
    @include size-M;

    padding: 0.888rem;
    width: 100%;
    padding-left:0;
    padding-bottom: 1rem;

    @extend %transition;

    //Focus State
    &:focus-visible,
    &:hover {
        @include color(border-color, 'accent-primary');
        outline: 0;
    }

    @include placeholder {
        @include color(color, 'body');
        opacity: 0.5;
    }
    &[type=text] {
        appearance: none;
    }
    &.wpcf7-not-valid {
        @include color(color, 'error');
    }
    @include breakpoint(XS) {
        font-size: 16px;
    }
}

.input-wrap,
.dropdown {
    position: relative;

    label {
        z-index: 2;
        position: absolute;
        top: 0.5rem;
        left: calc(0.888rem + 2px);
    }
    input,
    textarea {
        padding: 1.5rem 0.888rem 0.5rem 0.888rem;
        margin: 0;

        &:focus-visible {
            outline: 0;

            + label {
                @include color(color, 'accent-primary');
            }
        }
    }
    textarea {
        height: 10rem;
    }
    &.label-active {
        label {
            @include color(color, 'accent-primary');
        }
    }
    &.datepicker {
        cursor: pointer;
        border-radius: 0;

        input {
            cursor: pointer;
        }
    }
}

.wpcf7 {
    padding-top: 3.5rem;

    form div.wpcf7-response-output {
        margin: 0 0 2rem 0;
        border: 0;
        clear: both;
        @include color(color, 'background');
        padding: 1rem;
        @include color(background-color, 'accent-primary');
        position: relative;
        text-align: center;

        &.wpcf7-validation-errors {
            @include color(background-color, 'error');
        }
    }
    input[type="submit"] {
        margin-top: 2rem;
    }
}

.wpcf7-not-valid-tip {
    @include size-S;
}

.ajax-loader {
    display: block;
}

div.wpcf7 .ajax-loader {
    display: block;
    margin: 0 auto;
}

@import "dropdowns";
@import "checkboxes";
@import "textarea";