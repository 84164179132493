.copyright {
    padding-block: 1.5rem;
    @include breakpoint(L){
        .container{
            padding-right: 1rem;
            padding-left: 1rem;
        }
    }
    @include breakpoint(ML){
        .container{
            flex-direction: column;
            padding-top: 2rem;
        }
    }
    @include breakpoint(S){
        .container{
            .copyright-menu{
                flex-direction: column;
                text-align: left;
                align-items: center;
            }
        }
    }
    @include breakpoint(S) {
        padding: 1.5rem 0;
        padding-bottom: 0;
    }
    .copyright-menu p {
        margin-right: 2rem;
        &.copyright-text{
            margin-right: 3rem;
            @include breakpoint(MSS){
                margin-right: 1.5rem;
            }
            @include breakpoint(S){
                margin-right: 0rem;
            }
        }

        @include breakpoint(S) {
            margin-right: 0;
            margin-bottom: 0.5rem;
        }
    }
        .social-links{
            margin-right:1rem;
            .button.minor{
                margin: 0;
                padding: 1rem;
                padding-top:1.5rem;
                padding-bottom: 1.5rem;
                &::before{
                    display: none;
                }
            }
        }
    a {
        @include color(color, 'body');

        @include breakpoint(S) {
            padding: 0.5rem;
        }
        &:hover {
            @include color(color, 'accent-primary');
        }
    }
    ul {
        display: flex;
        flex-wrap: wrap;

        @include breakpoint(S) {
            justify-content: center;
            margin: 0;
            flex-direction: row;
            padding: 0.5rem 0;
        }
        li {
            @include breakpoint(S) {
                
                text-align: center;
            }
            a {
                @include size-S;
                display: block;
                margin-right: 1.5rem;

                @include breakpoint(S) {
                    margin: 0;
                    padding: 0.5rem;
                }
            }
        }
    }
}

.grecaptcha-badge {
    display: none !important;
}

.error.notice {
    display: none;
    position: fixed;
    width: 17em;
    right: 5em;
    bottom: 2em;
    z-index: 20;
    @include color(color, 'background');
    font-size: 16px;
    border-radius: 8px;
    @include color(background-color, 'background');
    border: 1px solid;
    @include color(border-color, 'body');
    
    :before {
        content: "Caching is disabled";
        display: block;
        font-weight: 700;
        @include color(color, 'body');
    }
    p {
        margin-bottom: 0em;
        padding: 2em;
    }
}

.js-category-target {
    @extend %transition;

    &.filtered {
        display: none;
    }
    &.fade-out {
        opacity: 0;
    }
}

.footer {
    clear: both; // just in case any floating elements
}

@keyframes textFade {
    from {
        opacity: 0;
      }
    
      to {
        opacity: 1;
      }
}
@keyframes popupOut {
    from {
        opacity: 1;
        visibility: visible;
      }
    
      to {
        opacity: 0;
        visibility: hidden;
      }
}

.preload-text-container{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 999;
    animation: popupOut 1.2s ease-in forwards;
    animation-delay: 3s;
   
    h2{
        @include color(color, 'body');
        animation: textFade 1.5s ease-in;
    }

}