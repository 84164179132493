.single-post {
    .banner-block {
        padding-bottom: 0;
        h1 {
            @include heading-1;
            .subtitle {
                margin-top: 0.5rem;
                @include subtitle-1;
                @include size-XL;
            }
        }
        .post-excerpt{
            p{
                @include subtitle-1;
                @include size-XL;
            }
        }
        .post-info-container{
            .overline{
                span{
                    &:first-child{
                        margin-right: 0.5rem;
                    }
                    &:last-child{
                        margin-left: 0.5rem;
                    }
                }
            }
        }
        .small-circle{
            margin: 0 auto;
            margin-top: 4.5rem;
            width: 4px;
            height: 4px;
            @include color(background-color, 'body');
            border-radius: 100%;
        }
    }
}
.events-template-default{
    .banner-block{
        padding-top: 16rem;
        h1{
            font-family: $font-stack-headings;
            @include size-3XL;
        }
        .events-actions {
            justify-content: center;
            margin-top: 4.5rem;
            .addeventatc {
                margin-left: 2rem;
        
                .atc-button{
                    color:white;
                    svg{
                        margin-right: 0.75rem;
                        color:white;
                    }
                }
            }
        }
        .post-info-container{
            .overline{
                span{
                    &:first-child{
                        margin-right:0.5rem;

                    }
                    &:last-child{
                        margin-left: 0.5rem;
                    }
                }
            }
        }
        .post-excerpt{
            .subtitle-1{
                p{
                    @include subtitle-1;
                }

            }
        }
        .small-circle{
            margin: 0 auto;
            margin-top: 4.5rem;
            width: 4px;
            height: 4px;
            @include color(background-color, 'body');
            border-radius: 100%;
        }
    }
    .share-container-sticky .post-share .share-text{
        border-color: white;
    }
    @include breakpoint(S){
        .container.more-articles{
            .past-events-container{
                .pe-events{
                    display: flex;
                    flex-direction: column;
                    gap:1.5rem;
                    .event-block{
                        display: flex;
                        flex-direction: column;
                        margin-left: 0;
                        margin-right: 0;
                        .event-content{
                            padding-left: 0;
                            h4{
                                @include size-XL;
                            }
                        }
                        .event-img-outer{
                            width: calc(100% - 5.142857142857143rem);
                            margin-bottom: 2rem;
                            .event-img{
                                height: 25.714285714285715rem;
                            }
                        }
                    }
                }
            }
        }
        .banner-block{
            .events-actions{
                display: flex;
                flex-direction: column;
                gap: 2.5rem;
                .addeventatc{
                    margin-left: 0;
                }
            }
        }
        .share-container-sticky{
            .post-share{
                background-color: var(--color-background);
                .button.icon{
                    border-color:white;
                    &:last-child{
                        border-color:white;
                    }
                }
            }
        }
    }
    .post-details.event-details{
        padding-top:0;
        margin-bottom: 3rem;
    }
    .thumb-image{
        max-width:48.875rem;
        height:65.125rem;
        width: 100%;
        margin: 0 auto;
        position: relative;
        @include breakpoint(S){
            height:31.428571428571427rem;
        }
        img{
            width: 100%;
            height: 100%;
            position:absolute;
            object-fit: cover;
        }
    }
    .more-articles{
        @include color(background-color, 'body');
        .pe-header{
            h4{
                color: $color-body;
            }
            width: 100%;
            border-bottom: 1px solid #1A1A1A;
            margin-bottom: 4.5rem;
        }


            .event-block {
                width: calc(50% - 2rem - 1px);
                margin-left: 1rem;
                margin-right: 1rem;
                margin-bottom: 0rem;

                @include breakpoint(XS) {
                    width: calc(100% - 2rem - 1px);
                }
                .event-img-outer {
                    width: 50%;

                    @include breakpoint(S) {
                        width: 6rem;
                        flex-shrink: 0;
                    }
                }
                .event-img {
                    position: relative;
                    height: 22.5rem;
                    padding-bottom: 100%;
                    width: 100%;

                    @include breakpoint(S) {
                        height: 8.5rem;
                        padding: 0;
                    }
                }
                .event-content {
                    width: 50%;
                    padding-top: 0.75rem;
                    padding-left: 2rem;
                    min-width: 16rem;
                   // max-width: 17.1875rem;

                    display: flex;
                    flex-direction: column;
                    justify-content: end;

                    @include breakpoint(S) {
                        min-width: 0;
                        width: 100%;
                    }
                    p, h4 {
                        color: $color-body;
                        svg {
                            color: $color-body;
                            margin-right: 0.75rem;
                        }
                    }
                    .button{
                        color: $color-body;
                        svg {
                            color: $color-body;
                        }
                    }   
                }
            }

            .past-events-container{
                width: 100%;
                .pe-events{
                    display:flex;
                    flex-direction: row;
                    overflow: scroll;
                    gap:3rem;
                    .event-block-past{
                        display:flex;
                        min-width: 16.5rem;
                        width: calc(100% - 10rem);
                        flex-direction: column;
                        .event-img-outer{
                            width: 100%;
                            min-width: 16.5rem;
                            height: 22rem;
                            position: relative;
                            margin-bottom: 2rem;
                            img{
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        .event-content{
                            p, h4{
                                color: $color-body;
                            }
                        }
                    }
                }
            }
        }
    }
    .about-author-blog{
        display: flex;
        flex-direction: row;
        gap:4rem;
        max-width: 48.625rem;
        margin: 0 auto;
        margin-top:8.8125rem;
        margin-bottom: 9rem;
        @include breakpoint(S){
            flex-direction: column;
            padding-left: 2rem;
            padding-right: 2rem;
            margin-bottom: 5.25rem;
            margin-top:5.25rem;
        }
        .author-image{
            width:15rem;
            height:15rem;
            display: flex;
            border-radius: 100%;
            flex: none;
            position: relative;
            img{
                position: absolute;
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-radius: 100%;
            }
        }
}
.share-container-sticky{
    position: fixed;
    right: 1.5625rem;
    top: 21.125rem;
    .post-share{
        flex-direction: column;
        a.button.icon{
            margin-right: 0;
            border-radius: 0;
            width:3rem;
            height: 3rem;
            border-bottom: 0;
            &:last-child{
                border-bottom: solid 1px;
            }
        }
        .share-text{
            margin-left: 1rem;
            border: solid 1px black;
            height: 6rem;
            width: 3rem;
            border-bottom: none;
        }
        .overline{
            transform: rotate(90deg);
            width: fit-content;
            position: relative;
            top: 2.5rem;
           
        }
    }
    @include breakpoint(S){
        top: unset;
        bottom: 1rem;
        z-index: 9;
        left: 0;
        right: 0;
        margin: 0 auto;
        display: flex;
        justify-content: center;

        .post-share{
            flex-direction: row;
            background-color: white;
            a.button.icon{
                border: solid 1px black;
                border-right:none;
                border-bottom: solid 1px black;
                &:last-child{
                    border: solid 1px black;
                }
            }
            .share-text{
                width:6rem;
                height:3rem;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: unset;
                border: solid 1px black;
                border-right:none;
                .overline{
                    transform: unset;
                    top:unset;
                    margin-bottom: 0;
                }
            }
            a{
                margin-left: 0;
            }
        }
    }
}

.blog-post {
    padding-top: 4.5rem;
    p,
    li {
        font-family: $font-stack-headings;
        max-width: 50rem;

        @include breakpoint(S) {
            max-width: calc(100vw - 4rem);
        }
    }
    p {
        margin-bottom: 1.75rem;
    }
    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
        margin-bottom: 2rem;
        max-width: 50rem;

        @include breakpoint(S) {
            max-width: calc(100vw - 4rem);
        }
    }
    h2 {
        margin-top: 4.25rem;
    }
    h3,
    h4 {
        margin-top: 1.75rem;
    }
    blockquote {
        margin-top: 6rem;
    }
    blockquote,
    .wp-block-embed,
    ul,
    ol,
    hr {
        margin-bottom: 1.75rem;
        max-width: 50rem;
        margin-left: auto;
        margin-right: auto;

        @include breakpoint(S) {
            max-width: calc(100vw - 4rem);
        }
    }
    .wp-block-image {
        figcaption {
            margin-top: 0;
            font-family: $font-stack-headings;
        }
    }
}

.post-actions {
    max-width: 50rem;
    margin: 6rem auto;

    @include breakpoint(XS) {
        margin: 4rem auto;
    }
}

.more-articles {
    padding-top: 4.25rem;
    padding-bottom: 4.25rem;

    @include breakpoint(XS) {
        padding-top: 4.5rem;
        padding-bottom: 3rem;
    }
}

.posts-grid {

    
    &.rel-posts {
        padding-top: 3.5rem;
    }
    .post-item-img {
        display: block;
        height: 23rem;
        width: 100%;
        

        img {
            height:100%;
            width: 100%;

            @supports (aspect-ratio: initial) {
                aspect-ratio: 4 / 3;
            } 

            @include breakpoint(S) {

                @supports not (aspect-ratio: initial) {
                    height: 10rem;
                } 
            }
            @include breakpoint(XS) {
            
                @supports not (aspect-ratio: initial) {
                    height: 15rem;
                } 
            }
        }
    }
    .post-item {
        margin-left: 1rem;
        margin-right: 1rem;
        margin-bottom: 3rem;
        position: relative;
        @include breakpoint(S){
            margin:0;
            flex-direction: column;
            align-items: flex-start;
        }

        .read-more-button{
            margin-top: auto;
            @include breakpoint(S){
                left:0;
            }
        }

        @include breakpoint(XS) {
            display: flex;
            margin-bottom: 3rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
        &.third {
            width: calc(33.333% - 2rem);
            flex-grow: unset;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @include breakpoint(S) {
                width: 100%;

                .post-item-img {
                    margin-bottom: 0;
                    width: 100%;
                    position: relative;
                    aspect-ratio: 1 / 1;
                    margin-bottom: 2rem;
                    img {
                        width:100%;
                        height: 100%;
                        position: absolute;
                        object-fit: cover;
                    }
                }
            }
        }
        h2,
        h3,
        h4 {
            a {
                @include color(color, 'body'); 

                &:hover {
                    @include color(color, 'accent-primary');
                }
            }
        }
        &.feat-post {
            width: 100%;
            flex-basis: unset;
            gap:9.375rem;
            position: relative;
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 9rem;
            @include breakpoint(S){
                gap:1.5rem;
                margin-bottom: 6.464285714285714rem;
            }

            .graphic-offset{
                position: absolute;
                left: 0;
                width: 24rem;
                height: calc(100%);
                background: #E3DFD4;
                z-index: 0;
                background-color: var(--graphic-color);
                @include breakpoint(S){
                    top:0;
                    width: 100%;
                    height: 30%;
                }
            }

            .post-item-img {
                width: 60%;
                position: relative;
                aspect-ratio: 1/1;
                margin-left:7.625rem;
                margin-top:3.9375rem;
                margin-bottom: 3.9375rem;
                height: unset;
                img{
                    height: 100%;
                }

                @include breakpoint(S) {
                    width: calc(100% - 4rem);
                   // height: 15rem;
                   margin-left: 0;
                   margin: 0 auto;
                   margin-top: 2rem;
                   padding-left: 2rem;
                   padding-right: 2rem;
                   margin-bottom: 0;
                }
            }
            .feat-post-content {
                width: 40%;
                margin-right: 7.5rem;
                padding-top: 3.5rem;
                padding-bottom: 3.5rem;
                display: flex;
                flex-direction: column;
                align-self: center;
                .button{
                    width: fit-content;
                }

                @include breakpoint(S) {
                    width: 100%;
                    padding: 0;
                    margin-right: 0;
                    padding-left: 2rem;
                    padding-right: 2rem;
                }
            }
        }
    }
}

.post-details {
    padding-top: 6rem;
    margin-bottom: 9rem;

    @include breakpoint(XS) {
        padding-top: 4.5rem;
        margin-bottom: 8rem;
    }
    &.thin {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        margin-bottom: 9rem;
        border-bottom: 1px solid;
        @include color(border-color, 'body-10');
    
        @include breakpoint(XS) {
            margin-bottom: 4.5rem;

            .post-share {
                padding-top: 1.5rem;
            }
        }
    }
    &.has-excerpt {
        .post-info {
            min-width: 24rem;
            padding-right: 4.25rem;
            margin-right: 4.25rem;
            border-right: 1px solid;
            @include color(border-color, 'body-10');

            @include breakpoint(M) {
                min-width: 20rem;
            }
            @include breakpoint(XS) {
                min-width: auto;
                width: 100%;
                padding-right: 0;
                margin-right: 0;
                border-right: 0;
                border-bottom: 1px solid;
                @include color(border-color, 'body-10');
                padding-bottom: 3rem;
                margin-bottom: 3rem;
            }
            p:last-child {
                margin-bottom: 0;
            }
        }
    }
    .intro-content {
        p {
            @include size-L;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &.event-details {
        @include breakpoint(XS) {
            padding-top: 2.25rem;
            margin-bottom: 4.5rem;

            .post-info {
                padding-bottom: 2rem;
            }
        }
    }
}

.post-info {
    strong {
        padding-right: 0.5rem;
    }
    a {
        @include color(color, 'body');

        &:hover {
            @include color(color, 'accent-primary');
        }
        &:after {
            content: ",";
            padding-right: 0.25rem;
        }
        &:last-child {
            &:after {
                display: none;
            }
        }
    }
}

.post-share {
    .button {
        margin-left: 1rem;
    }
}

.posts-nav {
    border-top: 1px solid;
    @include color(border-color, 'body');
    @include breakpoint(S){
        margin-left: 2rem;
        margin-right: 2rem;
    }

  
    a {
        @include color(color, 'body');

        @include breakpoint(XS) {
            padding: 1.5rem 2rem;
            border-bottom: 1px solid;
            @include color(border-color, 'body-10');
        }
        &:hover {
            @include color(color, 'accent-primary');

            .icon {
                @include color(border-color, 'accent-primary');

                svg {
                    @include color(color, 'accent-primary');
                }
            }
        }
        &.prev {
            .icon {
                margin-right: 1.5rem;
            }
            &:hover {
                .icon {
                    transform: translateX(-0.25rem);
                }
            }
        }
        &.next {
            .icon {
                margin-left: 1.5rem;
            }
            &:hover {
                .icon {
                    transform: translateX(0.25rem);
                }
            }
        }
    }
}

.loadmore-wrapper {
    padding-top: 2rem;
}

.offer-share {
    .bordered {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        border-bottom: 1px solid;
        @include color(border-color, 'body-10');
    }
}

.addeventatc {
    cursor: pointer;
    position: relative;

    @include breakpoint(XS) {
        margin-bottom: 1.5rem;
    }
    &:hover,
    &.addeventatc-selected {
        .atc-button {
            @include color(color, 'accent-primary');
        }
    }
    .atc-button {
        @extend %transition;

        svg {
            margin-left: 0.75rem;
        }
        &:after {
            content: "";
            width: 24px;
            height: 24px;
            background-image: url("data:image/svg+xml, %3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 21.5C17.2467 21.5 21.5 17.2467 21.5 12C21.5 6.75329 17.2467 2.5 12 2.5C6.75329 2.5 2.5 6.75329 2.5 12C2.5 17.2467 6.75329 21.5 12 21.5Z' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 17.5V6.5' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M17.5 12H6.5' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            margin-left: 0.75rem;

            @include breakpoint(XS) {
                width: 21px;
                height: 21px;
                background-image: url("data:image/svg+xml, %3Csvg width='21' height='21' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 21.5C17.2467 21.5 21.5 17.2467 21.5 12C21.5 6.75329 17.2467 2.5 12 2.5C6.75329 2.5 2.5 6.75329 2.5 12C2.5 17.2467 6.75329 21.5 12 21.5Z' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 17.5V6.5' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M17.5 12H6.5' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            }
        }
    }
    .copyx {
        display: none;
    }
    .addeventatc_dropdown {
        position: absolute;
        @include color(background-color, 'background');
        border: 1px solid;
        @include color(border-color, 'body');
        margin-top: 0.75rem;
        min-width: 14rem;
        display: none;

        span {
            display: block;
            padding: 1.25rem 1rem;
            line-height: 1;
            border-top: 1px solid;
            @include color(border-color, 'body');
            @include color(color, 'body');
            @extend %transition;

            em {
                @extend %transition;
                font-size: 0.75rem;
            }
            &:first-child {
                border-top: 0;
            }
            &:hover {
                @include color(background-color, 'accent-primary');
                @include color(color, 'background');

                em {
                    @include color(color, 'background');
                }
            }
        }
    }
}

.post-feat-img {
    width: 100%;
    //height: 0;
    //padding-top: 56.25%;
    aspect-ratio: 1 / 1;
    position: relative;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.advanced-times{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap:1.5rem;
    padding-bottom: 5rem;
    .time-card{
        padding: 2rem;
        display: flex;
        flex-direction: column;
        @include breakpoint(S){
            text-align: center;
            align-items: center;
        }
    }
    @include breakpoint(S){
        grid-template-columns: 1fr;
    }
}
.remove-bottom-spacing.banner-block{
    padding-bottom: 0;
}

html body{
#moove_gdpr_cookie_info_bar{
    inset: 0 !important;
    width: 100% !important;
    height: 100% !important;
    justify-content: center !important;
    display: flex;
    margin: 0 auto !important;
    background-color: #0000007d !important;
    max-height: 100% !important;
    align-items: center!important;
    font-family: $font-stack-primary !important;

    .moove-gdpr-info-bar-container{
    display: flex !important;
    max-width: 56rem !important;
    align-items: center !important;
    background: white !important;
    height: fit-content !important;
    padding: 5rem 2rem !important;
    }

    .moove-gdpr-info-bar-content{
        text-align: center !important;
        width: 100% !important;
        flex-direction: column !important;
        .moove-gdpr-button-holder{
            padding-left: unset !important;
            padding-top: 2rem !important;
            .mgbutton{
                padding: 0.75rem 1.5rem !important;
            }
        }
    }
}
}

.error-page.banner-block{
    margin-bottom: 0;
}