.footer {
    @include color(background-color, 'background-alt');

    @include breakpoint(XS) {
        padding-top: 2.75rem;
        padding-bottom: 2.75rem;
    }
    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 2.5rem;

        @include breakpoint(XS) {
            li {
                width: 50%;
            }
        }
        a {
            margin: 0.5rem 1rem;
            font-weight: 400;
            @include color(color, 'body');
            display: block;

            &:hover {
                @include color(color, 'accent-primary');
            }
        }
    }
    .footer-signup{
        display: flex;
        flex-direction: row;
        padding-top: 6rem;
        padding-bottom: 6rem;
        justify-content: center;
        gap:3.46875rem;
        align-items: center;
        @include breakpoint(S){
            flex-direction: column;
            padding-left:2rem;
            padding-right: 2rem;
        }
        .signup-graphic{
            width: 22.21875rem;
            @include breakpoint(MS){
                width: 15rem
            }
            @include breakpoint(S){
                width: 11rem;
                align-self: center;
            }
            svg{
                width: 100%;
                height: 100%;
                @include color(color, 'body');
            }
        }
        .signup-form{
            max-width: 35.875rem;
            @include breakpoint(M){
                max-width: 30rem;
            }
            @include breakpoint(MS){
                max-width: 25rem;
            }
            @include breakpoint(S){
                max-width: 30rem;
            }
            .signup-text{
                @include size-2XL;
                font-weight: 300;
                margin-bottom: 3rem;
                @include breakpoint(MS){
                    @include size-2XL;
                }
            }
            input:focus-visible, input:hover, textarea:focus-visible, textarea:hover, .dropdown:focus-visible, .dropdown:hover{
                @include color(border-color, 'body');
            }
            label{
                @include size-S;
                font-family: $font-stack-primary;
                font-weight: 400;
            }
            button[type="submit"]{
                border:none;
                @include color(border-color, 'body');
                border-bottom: solid 1px;
                padding-right: 0;
                &:hover{
                    background-color: transparent;
                }
            }
            .newsletter{
                margin-bottom: 2rem;
            }
            //Custom Checkbox Styles: 

            /* The container */
            .checkbox-container {
                display: block;
                position: relative;
                padding-left: 35px;
                margin-bottom: 12px;
                cursor: pointer;
                font-size: 22px;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                display: flex;
            }
            
            /* Hide the browser's default checkbox */
            .checkbox-container input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }
            
            /* Create a custom checkbox */
            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 1.25rem;
                width: 1.25rem;
                background-color: transparent;
                border: 1px solid;
                @include color(border-color, 'body');
                box-sizing: border-box;
                border-radius: 2px;
            }
            
            /* On mouse-over, add a grey background color */
            .checkbox-container:hover input ~ .checkmark {
                @include color(border-color, 'accent-primary');
            }
            
            /* When the checkbox is checked, add a blue background */
            .checkbox-container input:checked ~ .checkmark {
                @include color(background-color, 'accent-primary');
                @include color(border-color, 'accent-primary');
            }
            
            /* Create the checkmark/indicator (hidden when not checked) */
            .checkmark:after {
                content: "";
                position: absolute;
                display: none;
            }
            
            /* Show the checkmark when checked */
            .checkbox-container input:checked ~ .checkmark:after {
                display: block;
            }
            
            /* Style the checkmark/indicator */
            .checkbox-container .checkmark:after {
                left: 6px;
                top: 3px;
                width: 0.3375rem;
                height: 0.5375rem;
                border: solid white;
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
    }
}

.social-links {
    .button {
        margin: 0 0.5rem;
    }
}